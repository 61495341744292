<template>
  <section class="carousel-section ">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

      <div class="carousel-inner">

        <div class="carousel-item active">
          <!-- <img class="d-block w-100" src="/img/carousel/carousel_3.png"> -->
          <img class="d-block w-100" style="height:90px;" src="/img/carousel/freespin.png">
        </div>
        <div class="carousel-item">
          <!-- <img class="d-block w-100" src="/img/carousel/comet-crash.png"> -->
          <img class="d-block w-100" style="height: 90px;" src="/img/carousel/falcon-bet1.jpg">
        </div>
        <div class="carousel-item">
          <!-- <img class="d-block w-100" src="/img/carousel/crash-mamilli.png"> -->
          <img class="d-block w-100" style="height: 90px;" src="/img/carousel/falcon-bet3.jpg">
        </div>
        <!-- <div class="carousel-item ">
          <img class="d-block w-100" src="/img/carousel/f1.png">
        </div> -->
        <!-- <div class="carousel-item "> -->
          <!-- <img class="d-block w-100" src="/img/carousel/carousel_4.png"> -->
          <!-- <img class="d-block w-100" src="/img/carousel/freespin.png">
        </div> -->
        <!-- <div class="carousel-item ">
          <img class="d-block w-100" src="/img/carousel/JACKPOT.png">
        </div> -->

      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </section>
</template>
<script>

import axios from "@/services/cms";

export default {
  name: "Slider",
  data() {
    return{
    carouselImages:[],
    }
  },
  mounted(){
    this.getCarousels();
    this.getFaq(); 
  },
  methods:{

    async getCarousels() {
     try {
        var response = await axios.get("/get/banners", {
  headers: {
    "Accept": "application/json"
  }
});
        if (response.status === 200) {
          // Adjust based on the structure of response.data
          // this.carouselImages = response.data.banners.map(banner => banner.url);
        }
      } catch (error) {
        console.error("An error occurred while fetching carousel:", error.response?.data || error.message);
      }
    },
    async getFaq() {
     try {
        const response = await axios.get("/get/faq", {
  headers: {
    "Accept": "application/json"
  }
});
        if (response.status === 200) {
          // Adjust based on the structure of response.data
console.log(response.data);
}
      } catch (error) {
        console.error("An error occurred while fetching carousel:", error.response?.data || error.message);
      }
    }


  }
  
}
  
  
</script>